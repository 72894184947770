<template>
	<v-card outlined class="pa-4">
		<v-row no-gutters class="d-flex align-start">
			<v-col class="d-flex align-center justify-center pr-3" cols="3">
				<v-skeleton-loader
					class="mt-10"
					v-if="loading"
					type="avatar"
				></v-skeleton-loader>
				<div v-else>
					<v-avatar outlined size="94">
						<v-img
							:src="project.logo.publicURL"
							alt="Project Logo"
							v-if="
								project.logo && project.logo.publicURL !== null
							"
						></v-img>
						<p v-else class="font-weight-bold">No Image Uploaded</p>
					</v-avatar>
				</div>
			</v-col>
			<v-col cols="7">
				<v-skeleton-loader
					v-if="loading"
					type="card"
				></v-skeleton-loader>
				<div v-else>
					<h3>{{ project.name }}</h3>
					<a
						:href="'//' + project.domain"
						v-if="project.domain"
						target="_blank"
						>https://{{ project.domain }}</a
					>
					<h4
						style="font-weight: 400"
						class="mt-3"
						v-if="project.createdAt"
					>
						Created on: {{ project.createdAt.substring(0, 10) }}
					</h4>
				</div>
			</v-col>
			<v-col cols="2" class="d-flex justify-center">
				<v-skeleton-loader
					v-if="loading"
					type="button"
				></v-skeleton-loader>

				<div v-else>
					<v-btn v-if="isHosted" color="#4CAF50" style="color: white">
						Hosted</v-btn
					>
					<v-btn v-else color="#EF6C00" style="color: white"
						>Not Hosted</v-btn
					>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	data() {
		return {
			loading: true,
			project: {},
			isHosted: false,
		}
	},
	created() {
		this.getProject()
	},
	methods: {
		getProject() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `project/${projectId}`,
			}).then((res) => {
				this.project = res.data
			})

			this.axios({
				method: 'get',
				url: `/${projectId}/hosting`,
			}).then((res) => {
				let sslIssuedOn = res.data.sslIssuedOn
				if (!sslIssuedOn) {
					this.isHosted = true
				}
				this.loading = false
			})
		},
	},
}
</script>
<style scoped>
.image-wrapper {
	max-height: 150px;
	width: 100%;
}
</style>
