<template>
	<v-card class="pa-8">
		<v-row class="mx-0">
			<v-col cols="6" class="pa-0">
				<h2>Content Management</h2>
			</v-col>
			<v-spacer></v-spacer>
			<v-col cols="2" class="pa-0">
				<v-select
					:items="items"
					@input="setSelected"
					label="Time Range"
					outlined
					dense
				></v-select>
			</v-col>
			<v-col cols="6" class="pa-0 mt-4 pr-3 d-flex">
				<v-card v-if="loading" class="pa-4" outlined width="100%">
					<v-skeleton-loader type="card"></v-skeleton-loader>
				</v-card>
				<v-card v-else class="pa-4" outlined width="100%">
					<h4 class="ma-0">BLOGS</h4>

					<p class="mr-2 my-2 mb-4">
						Total Blogs : {{ blogData.overallTotal }}
					</p>
					<div class="d-flex justify-center align-center">
						<LineChart
							:chart-data="blogData"
							:options="chartOptions"
							v-if="blogData.overallTotal !== 0"
						></LineChart>
						<v-card v-else class="create pb-5 mb-0 pa-4">
							<v-img
								src="@/assets/img/common/create.svg"
								aspect-ratio="1"
								class="no-data"
								max-width="400"
								max-height="300"
							></v-img>
							<div class="d-flex flex-column">
								<p class="text-center mt-6 create">
									Currently there is no blog added.
								</p>
								<v-btn
									depressed
									outlined
									color="blue"
									large
									class="add-new mt-10 align-self-center"
									:to="`/p/${projectId}/blog/create`"
								>
									Create Blog
								</v-btn>
							</div>
						</v-card>
					</div>
				</v-card>
			</v-col>
			<v-col cols="6" class="pa-0 mt-4 pl-3 d-flex">
				<v-card v-if="loading" class="pa-4" outlined width="100%">
					<v-skeleton-loader type="card"></v-skeleton-loader>
				</v-card>
				<v-card v-else class="pa-4" outlined width="100%">
					<h4 class="ma-0">CASE STUDIES</h4>
					<p class="mr-2 my-2 mb-4">
						Total Case Studies: {{ caseStudyData.overallTotal }}
					</p>
					<div class="d-flex justify-center align-center">
						<LineChart
							:chart-data="caseStudyData"
							:options="chartOptions"
							v-if="caseStudyData.overallTotal !== 0"
						></LineChart>
						<v-card v-else class="create pb-5 mb-0 pa-4">
							<v-img
								src="@/assets/img/common/create.svg"
								aspect-ratio="1"
								class="no-data"
								max-width="400"
								max-height="300"
							></v-img>
							<div class="d-flex flex-column">
								<p class="text-center mt-6 create">
									Currently there is no case study added.
								</p>
								<v-btn
									depressed
									outlined
									color="blue"
									large
									class="add-new mt-10 align-self-center"
									:to="`/p/${projectId}/case-study/create`"
								>
									Create Case Study
								</v-btn>
							</div>
						</v-card>
					</div>
				</v-card>
			</v-col>
			<v-col
				cols="6"
				class="pa-0"
				v-for="(domain, index) in domainData"
				:key="index"
			>
				<v-card
					v-if="!loading"
					outlined
					class="pa-4"
					:class="getClass(index)"
					style="color: white"
				>
					<div class="d-flex flex-row justify-space-between">
						<h4>{{ domain.name.toUpperCase() }}</h4>
						<p>Overall Total: {{ domain.overallTotal }}</p>
					</div>
					<p class="text-center" style="font-size: 50px">
						{{ domain.total }}
					</p>
					<div class="px-12 d-flex flex-row justify-space-between">
						<div>
							<p>Published</p>
							<p class="text-center" style="font-size: 30px">
								{{ domain.published }}
							</p>
						</div>
						<div>
							<p>Unpublished</p>
							<p class="text-center" style="font-size: 30px">
								{{ domain.unPublished }}
							</p>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import LineChart from '@/components/Charts/LineChart.js'

export default {
	components: {
		LineChart,
	},
	data() {
		return {
			loading: true,
			projectId: this.$store.state.app.projectId,
			chartOptions: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								userCallback: function (label) {
									// when the floored value is the same as the value we have a whole number
									if (Math.floor(label) === label) {
										return label
									}
								},
							},
						},
					],
				},
				responsive: true,
				maintainAspectRatio: true,
			},
			domainData: [],
			blogData: {},
			caseStudyData: {},
			users: null,
			timeline: '7', //default to 7 days ago
			items: ['Last 7 days', 'Last 30 days', 'Last 6 months'],
		}
	},
	created() {
		this.getDashboard()
	},
	computed: {},
	methods: {
		getClass(index) {
			let className = ''
			if (index === 0) {
				className = 'card-blue'
			} else if (index === 1) {
				className = 'card-green'
			} else if (index === 2) {
				className = 'card-purple'
			} else {
				className = 'card-orange'
			}
			return className
		},
		setSelected(value) {
			if (value === 'Last 7 days') {
				this.timeline = '7'
			}
			if (value === 'Last 30 days') {
				this.timeline = '30'
			}
			if (value === 'Last 6 months') {
				this.timeline = '180'
			}
			this.getDashboard()
		},
		getDashboard() {
			const projectId = this.$store.state.app.projectId
			this.$emit('timeline-changed', this.timeline)
			this.axios({
				method: 'get',
				url: `project/${projectId}/dashboard`,
				params: {
					fromDate: this.timeline,
				},
			}).then((res) => {
				let result = res.data.data
				const months = [
					'JAN',
					'FEB',
					'MAR',
					'APR',
					'MAY',
					'JUN',
					'JUL',
					'AUG',
					'SEP',
					'OCT',
					'NOV',
					'DEC',
				]

				let blogs = result.Blogs.Blogs
				let blogsData = {
					overallTotal: 0,
					total: 0,
					fill: false,
					labels: [],
					datasets: [
						{
							fill: false,
							borderColor: '#7B1FA2',
							pointBackgroundColor: '#7B1FA2',
							pointBorderColor: '#7B1FA2',
							borderWidth: 1,
							backgroundColor: '#7B1FA2',
							data: [],
						},
						{
							fill: false,
							borderColor: '#00796B',
							pointBackgroundColor: '#00796B',
							pointBorderColor: '#00796B',
							borderWidth: 1,
							backgroundColor: '#00796B',
							data: [],
						},
					],
				}
				blogs.forEach((blog) => {
					let date = blog._id.startDate
					let month = date.substring(5, 7) - 1
					let day = date.substring(8, 10)

					blogsData['labels'].push(`${months[month]}/${day}`)
					blogsData.datasets[0].data.push(blog.published)
					blogsData.datasets[1].data.push(blog.notPublished)
					blogsData.total = blogsData.total + blog.total
				})
				blogsData.overallTotal = result.Blogs.overallTotal

				blogsData.datasets[0].label = `Published (${blogsData.datasets[0].data.reduce(
					(a, b) => a + b,
					0
				)})`
				blogsData.datasets[1].label = `Unpublished (${blogsData.datasets[1].data.reduce(
					(a, b) => a + b,
					0
				)})`
				this.blogData = blogsData

				let caseStudies = result.CaseStudies.CaseStudies
				let caseStudiesData = {
					overallTotal: 0,
					total: 0,
					fill: false,
					labels: [],
					datasets: [
						{
							fill: false,
							borderColor: '#039BE5',
							pointBackgroundColor: '#039BE5',
							borderWidth: 1,
							pointBorderColor: '#039BE5',
							backgroundColor: '#039BE5',
							data: [],
						},
						{
							fill: false,
							borderColor: '#FBC02D',
							pointBackgroundColor: '#FBC02D',
							pointBorderColor: '#FBC02D',
							borderWidth: 1,
							backgroundColor: '#FBC02D',
							data: [],
						},
					],
				}
				caseStudies.forEach((caseStudy) => {
					let date = caseStudy._id.startDate
					let month = date.substring(5, 7) - 1
					let day = date.substring(8, 10)
					caseStudiesData['labels'].push(`${months[month]}/${day}`)
					caseStudiesData.datasets[0].data.push(caseStudy.published)
					caseStudiesData.datasets[1].data.push(
						caseStudy.notPublished
					)
					caseStudiesData.total =
						caseStudiesData.total + caseStudy.total
				})
				caseStudiesData.overallTotal = result.CaseStudies.overallTotal
				caseStudiesData.datasets[0].label = `Published (${caseStudiesData.datasets[0].data.reduce(
					(a, b) => a + b,
					0
				)})`
				caseStudiesData.datasets[1].label = `Unpublished (${caseStudiesData.datasets[1].data.reduce(
					(a, b) => a + b,
					0
				)})`
				this.caseStudyData = caseStudiesData

				this.domainData = []
				const domainsArr = [
					'Faqs',
					'Testimonials',
					'Services',
					'Professionals',
				]
				for (const domain in result) {
					if (domainsArr.includes(domain)) {
						let obj = {
							name: null,
							total: null,
							overallTotal: null,
							published: null,
							unPublished: null,
						}
						obj.name = domain
						obj.total = result[domain][domain].total
						obj.overallTotal = result[domain].overallTotal
						obj.published = result[domain][domain].published
						obj.unPublished = result[domain][domain].notPublished

						this.domainData.push(obj)
					}
				}
				this.loading = false
			})
		},
	},
}
</script>
<style scoped>
.card-blue {
	background-color: #40c4ff;
	margin-right: 10px;
}
.card-green {
	background-color: #43a047;
	margin-left: 10px;
}
.card-purple {
	background-color: #ab47bc;
	margin-right: 10px;
}
.card-orange {
	background-color: #ffab40;
	margin-left: 10px;
}
.create {
	font-size: 1.2rem;
}
</style>
