<template>
	<v-card class="pa-8">
		<v-row class="ma-0 d-flex justify-space-between">
			<v-col cols="4" class="pa-0 mr-10">
				<h2>Google My Business (GMB)</h2>
			</v-col>
			<v-col cols="3" class="pa-0 ml-16">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							label="Select Date Range"
							v-model="dateRangeText"
							readonly
							outlined
							dense
							v-bind="attrs"
							v-on="on"
							hide-details
						></v-text-field>
					</template>
					<v-date-picker
						v-model="dates"
						:show-current="false"
						:min="
							new Date(
								new Date().setMonth(new Date().getMonth() - 17) // 17 months ago because google only allow 18 months ago data
							)
								.toISOString()
								.substring(0, 10)
						"
						:max="
							new Date(new Date().valueOf() - 1000 * 60 * 60 * 23)
								.toISOString()
								.substring(0, 10)
						"
						range
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn text color="primary" @click="getInsights">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="3" class="pa-0">
				<v-select
					:disabled="!(dates.length === 2)"
					:items="locations"
					@input="setSelected"
					label="Select Location"
					outlined
					dense
					hide-details
				></v-select>
			</v-col>
		</v-row>
		<div class="d-flex justify-end align-center mt-4">
			<span class="mr-2">Graphical</span>
			<v-switch
				v-model="switch1"
				hide-details
				class="pa-0 ma-0"
			></v-switch>
			<span class="mr-2">Numberical</span>
		</div>
		<v-row v-if="selectedLocation">
			<GMBScoreCard
				v-if="switch1"
				:dataMap="dataMap"
				:loading="loading"
				:customerSearch="customerSearch"
				:reviewSummary="reviewSummary"
				:questionSummary="qaData"
				:photoQuantity="photoQuantity"
				:viewListing="viewListing"
				:photoViews="photoViews"
				:customerActions="customerActions"
				:gmbPosts="gmbPosts"
			/>
			<v-row v-else>
				<!-- Customer Search -->
				<v-col cols="6" class="pa-0 mt-4 pr-3 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card
						v-else
						class="pa-4 small-cards"
						outlined
						width="100%"
					>
						<h4 class="ma-0">
							How customers search for your business
						</h4>
						<div class="mt-8">
							<div v-if="customerSearch.datasets[0].total === 0">
								<img
									src="@/assets/img/common/no-info.svg"
									alt="No data found"
									class="no-data"
								/>
								<p class="font-weight-bold text-center">
									No data available for this interval. Try
									using other interval period.
								</p>
							</div>
							<div
								v-else
								class="d-flex flex-start justify-space-around"
							>
								<Doughnut
									:chart-data="customerSearch"
									:options="doughnutChartOptions"
								></Doughnut>
								<div
									v-for="(
										customeData, index
									) in customerSearch.datasets"
									:key="customeData.id"
									class="text-left ml-4"
								>
									<div class="py-8"></div>
									<div
										v-for="customValue in customeData.data"
										:key="customValue.id"
										class="mb-4"
									>
										<p class="d-block subtitle-2 pa-0">
											<span
												class="graph-box"
												:style="{
													'background-color':
														customeData.backgroundColor,
												}"
											></span
											>{{
												customerSearch.labels[index++]
											}}
										</p>
										<p class="subtitle-2 text-left">
											<span class="label">Total : </span
											>{{ customValue }}
										</p>
									</div>
								</div>
								<v-card>
									<div
										class="
											px-2
											py-2
											compare
											deep-purple
											darken-1
										"
									>
										<p
											class="
												subtitle-2
												label
												white--text
												mr-1
											"
										>
											Data Comparision:
										</p>
										<p
											class="
												caption
												label
												white--text
												text-center
											"
										>
											{{ previousMonth }}-{{
												currentMonth
											}}
										</p>
									</div>
									<div
										v-for="custSearch in dataMap.business"
										:key="custSearch.id"
										class="mt-1 mb-8 text-center"
										:class="[
											custSearch.difference >= 0
												? 'green--text'
												: 'red--text',
										]"
									>
										<span>
											{{ custSearch.difference }}
										</span>
										<span class="caption">
											({{
												Math.round(
													custSearch.percentDifference
												)
											}}%)
										</span>
									</div>
								</v-card>
							</div>
						</div>
					</v-card>
				</v-col>
				<!-- Review Summary -->
				<v-col cols="6" class="pa-0 mt-4 pl-3 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card
						v-else
						class="pa-4 small-cards"
						outlined
						width="100%"
					>
						<h4 class="ma-0">Reviews</h4>
						<div class="mt-8">
							<div
								v-if="
									reviewSummary === null ||
									reviewSummary.datasets[0].total === 0
								"
							>
								<img
									src="@/assets/img/common/no-info.svg"
									alt="No data found"
									class="no-data"
								/>
								<p class="font-weight-bold text-center">
									No data available for this interval. Try
									using other interval period.
								</p>
							</div>
							<div
								v-else
								class="d-flex flex-start justify-space-around"
							>
								<div>
									<Doughnut
										:chart-data="reviewSummary"
										:options="doughnutChartOptions"
									></Doughnut>
									<h4 class="text-center mt-2">
										Average Rating
										{{ reviewSummary.averageRating }}
									</h4>
									<v-rating
										class="text-center"
										color="yellow darken-3"
										background-color="grey"
										empty-icon="$ratingFull"
										dense
										length="5"
										readonly
										half-increments
										:value="reviewSummary.averageRating"
										size="14"
									></v-rating>
								</div>
								<div
									v-for="(
										reviewData, index
									) in reviewSummary.datasets"
									:key="reviewData.id"
									class="text-left ml-4"
								>
									<div class="py-8"></div>
									<div
										v-for="reviewValue in reviewData.data"
										:key="reviewValue.id"
										class="mb-4"
									>
										<p class="d-block subtitle-2 pa-0">
											<span
												class="graph-box"
												:style="{
													'background-color':
														reviewData.backgroundColor,
												}"
											></span
											>{{ reviewSummary.labels[index++] }}
										</p>
										<p class="subtitle-2 text-left">
											<span class="label">Total : </span
											>{{ reviewValue }}
										</p>
									</div>
								</div>
								<!-- <v-card>
									<div
										class="px-2 py-2 compare deep-purple darken-1"
									>
										<p
											class="subtitle-2 label white--text mr-1"
										>
											Data Comparision:
										</p>
										<p
											class="caption label white--text text-center"
										>
											{{ previousMonth }}-{{
												currentMonth
											}}
										</p>
									</div>
									<div
										v-for="reviewCompare in dataMap.review"
										:key="reviewCompare.id"
										class="mt-1 mb-8 text-center"
										:class="[
											reviewCompare.difference >= 0
												? 'green--text'
												: 'red--text',
										]"
									>
										<span>
											{{ reviewCompare.difference }}
										</span>
										<span class="caption">
											({{
												Math.round(
													reviewCompare.percentDifference
												)
											}}%)
										</span>
									</div>
								</v-card> -->
							</div>
						</div>
					</v-card>
				</v-col>
				<!-- Question Summary -->
				<v-col cols="6" class="pa-0 mt-4 pr-3 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card
						v-else
						class="pa-4 small-cards"
						outlined
						width="100%"
					>
						<h4 class="ma-0">Q & A</h4>
						<div class="mt-8">
							<div
								v-if="
									questionSummary === null ||
									qaData.datasets[0].total === 0
								"
							>
								<img
									src="@/assets/img/common/no-info.svg"
									alt="No data found"
									class="no-data"
								/>
								<p class="font-weight-bold text-center">
									No data available for this interval. Try
									using other interval period.
								</p>
							</div>
							<div
								v-else
								class="d-flex flex-start justify-space-around"
							>
								<Doughnut
									:chart-data="qaData"
									:options="doughnutChartOptions"
								></Doughnut>
								<div
									v-for="(faqMain, index) in qaData.datasets"
									:key="faqMain.id"
									class="text-left ml-4"
								>
									<div class="py-8"></div>
									<div
										v-for="faqValue in faqMain.data"
										:key="faqValue.id"
										class="mb-4"
									>
										<p class="d-block subtitle-2 pa-0">
											<span
												class="graph-box"
												:style="{
													'background-color':
														faqMain.backgroundColor,
												}"
											></span
											>{{ qaData.labels[index++] }}
										</p>
										<p class="subtitle-2 text-left">
											<span class="label">Total : </span
											>{{ faqValue }}
										</p>
									</div>
								</div>
								<v-card>
									<div
										class="
											px-2
											py-2
											compare
											deep-purple
											darken-1
										"
									>
										<p
											class="
												subtitle-2
												label
												white--text
												mr-1
											"
										>
											Data Comparision:
										</p>
										<p
											class="
												caption
												label
												white--text
												text-center
											"
										>
											{{ previousMonth }}-{{
												currentMonth
											}}
										</p>
									</div>
									<div
										v-for="faqCompare in dataMap.qa"
										:key="faqCompare.id"
										class="mt-1 mb-8 text-center"
										:class="[
											faqCompare.difference >= 0
												? 'green--text'
												: 'red--text',
										]"
									>
										<span>
											{{ faqCompare.difference }}
										</span>
										<span class="caption">
											({{
												Math.round(
													faqCompare.percentDifference
												)
											}}%)
										</span>
									</div>
								</v-card>
							</div>
						</div>
					</v-card>
				</v-col>
				<!-- Photo Quantity -->
				<v-col cols="6" class="pa-0 mt-4 pl-3 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card
						v-else
						class="pa-4 small-cards"
						outlined
						width="100%"
					>
						<h4 class="ma-2">Photo Quantity</h4>
						<div class="my-8">
							<div v-if="photoQuantity.datasets[0].total === 0">
								<img
									src="@/assets/img/common/no-info.svg"
									alt="No data found"
									class="no-data"
								/>
								<p class="font-weight-bold text-center">
									No data available for this interval. Try
									using other interval period.
								</p>
							</div>
							<div
								v-else
								class="d-flex flex-start justify-space-around"
							>
								<Doughnut
									:chart-data="photoQuantity"
									:options="doughnutChartOptions"
									class="mx-auto"
								></Doughnut>
								<div
									v-for="(
										photoQty, index
									) in photoQuantity.datasets"
									:key="photoQty.id"
									class="text-left ml-4 mr-2"
								>
									<div class="py-8"></div>
									<div
										v-for="photoValue in photoQty.data"
										:key="photoValue.id"
										class="mb-4"
									>
										<p class="d-block subtitle-2 pa-0">
											<span
												class="graph-box"
												:style="{
													'background-color':
														photoQty.backgroundColor,
												}"
											></span
											>{{ photoQuantity.labels[index++] }}
										</p>
										<p class="subtitle-2 text-left">
											<span class="label">Total : </span
											>{{ photoValue }}
										</p>
									</div>
								</div>
								<v-card>
									<div
										class="
											px-2
											py-2
											compare
											deep-purple
											darken-1
										"
									>
										<p
											class="
												subtitle-2
												label
												white--text
												mr-1
											"
										>
											Data Comparision:
										</p>
										<p
											class="
												caption
												label
												white--text
												text-center
											"
										>
											{{ previousMonth }}-{{
												currentMonth
											}}
										</p>
									</div>
									<div
										v-for="photoCompare in dataMap.photoCount"
										:key="photoCompare.id"
										class="mt-1 mb-8 text-center"
										:class="[
											photoCompare.difference >= 0
												? 'green--text'
												: 'red--text',
										]"
									>
										<span>
											{{ photoCompare.difference }}
										</span>
										<span class="caption">
											({{
												Math.round(
													photoCompare.percentDifference
												)
											}}%)
										</span>
									</div>
								</v-card>
							</div>
						</div>
					</v-card>
				</v-col>
				<!-- View Listing -->
				<v-col cols="12" class="pa-0 mt-4 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card v-else class="pa-4" outlined width="100%">
						<h4 class="mb-5">
							Where customers view your business on Google
						</h4>
						<div class="d-flex justify-center align-center">
							<LineChart
								:height="300"
								:width="900"
								:chart-data="viewListing"
								:options="chartOptions"
							></LineChart>
						</div>
						<div
							class="
								d-flex
								justify-start
								align-center
								mt-4
								text-center
							"
						>
							<div class="invisible-box"></div>
							<div
								v-for="viewList in viewListing.datasets"
								:key="viewList.id"
								class="px-14"
							>
								<p class="d-block subtitle-2 pa-0">
									<span
										class="graph-box"
										:style="{
											'background-color':
												viewList.backgroundColor,
										}"
									></span
									>{{ viewList.label }}
								</p>
								<p class="subtitle-2">
									<span class="label">Total : </span
									>{{ viewList.total }}
								</p>
							</div>
						</div>
						<v-card class="d-flex justify-start align-center mt-4">
							<div
								class="
									d-flex
									align-center
									py-8
									px-6
									compare
									deep-purple
									darken-1
								"
							>
								<p class="subtitle-2 label white--text">
									Data Comparision:
								</p>
								<p
									class="
										subtitle-2
										label
										mx-2
										month-box
										white--text
									"
								>
									{{ previousMonth }}-{{ currentMonth }}
								</p>
							</div>
							<div
								v-for="custm in dataMap.custmView"
								:key="custm.id"
								class="text-center py-8 px-12"
								:class="[
									custm.difference >= 0
										? 'green--text'
										: 'red--text',
								]"
							>
								<span class="grey--text text--darken-1"
									>change:</span
								>
								<span class="my-2">
									{{ custm.difference }}
								</span>
								<span class="percent-box">
									<v-icon
										v-if="custm.difference >= 0"
										color="green"
										>mdi-trending-up</v-icon
									>
									<v-icon v-else color="red"
										>mdi-trending-down</v-icon
									>{{ Math.round(custm.percentDifference) }}%
								</span>
							</div>
						</v-card>
					</v-card>
				</v-col>
				<!-- Photo Views -->
				<v-col cols="12" class="pa-0 mt-4 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card v-else class="pa-4" outlined width="100%">
						<h4 class="ma-0">Photo Views</h4>
						<div class="d-flex justify-center align-center">
							<LineChart
								:height="300"
								:width="900"
								:chart-data="photoViews"
								:options="chartOptions"
							></LineChart>
						</div>
						<div
							class="
								d-flex
								justify-start
								align-center
								mt-4
								text-center
							"
						>
							<div class="invisible-box"></div>
							<div
								v-for="viewPhoto in photoViews.datasets"
								:key="viewPhoto.id"
								class="px-16"
							>
								<p class="d-block subtitle-2 pa-0">
									<span
										class="graph-box"
										:style="{
											'background-color':
												viewPhoto.backgroundColor,
										}"
									></span
									>{{ viewPhoto.label }}
								</p>
								<p class="subtitle-2">
									<span class="label">Total : </span
									>{{ viewPhoto.total }}
								</p>
							</div>
						</div>
						<v-card class="d-flex justify-start align-center mt-4">
							<div
								class="
									d-flex
									align-center
									py-8
									px-6
									compare
									deep-purple
									darken-1
								"
							>
								<p class="subtitle-2 label white--text">
									Data Comparision:
								</p>
								<p
									class="
										subtitle-2
										label
										mx-2
										month-box
										white--text
									"
								>
									{{ previousMonth }}-{{ currentMonth }}
								</p>
							</div>
							<div
								v-for="pic in dataMap.photoView"
								:key="pic.id"
								class="text-center py-8 px-12"
								:class="[
									pic.difference >= 0
										? 'green--text'
										: 'red--text',
								]"
							>
								<span class="grey--text text--darken-1"
									>change:</span
								>
								<span class="my-2">
									{{ pic.difference }}
								</span>
								<span class="percent-box">
									<v-icon
										v-if="pic.difference >= 0"
										color="green"
										>mdi-trending-up</v-icon
									>
									<v-icon v-else color="red"
										>mdi-trending-down</v-icon
									>{{ Math.round(pic.percentDifference) }}%
								</span>
							</div>
						</v-card>
					</v-card>
				</v-col>
				<!-- Customer Actions -->
				<v-col cols="12" class="pa-0 mt-4 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card v-else class="pa-4" outlined width="100%">
						<h4 class="ma-0">Customer actions</h4>
						<div class="d-flex justify-center align-center">
							<LineChart
								:height="300"
								:width="900"
								:chart-data="customerActions"
								:options="chartOptions"
							></LineChart>
						</div>
						<div
							class="
								d-flex
								justify-start
								align-center
								mt-4
								text-center
							"
						>
							<div class="invisible-box"></div>
							<div
								v-for="actionData in customerActions.datasets"
								:key="actionData.id"
								class="px-11"
							>
								<p class="d-block subtitle-2 pa-0">
									<span
										class="graph-box"
										:style="{
											'background-color':
												actionData.backgroundColor,
										}"
									></span
									>{{ actionData.label }}
								</p>
								<p class="subtitle-2">
									<span class="label">Total : </span
									>{{ actionData.total }}
								</p>
							</div>
						</div>
						<v-card class="d-flex justify-start align-center mt-4">
							<div
								class="
									d-flex
									align-center
									py-8
									px-6
									compare
									deep-purple
									darken-1
								"
							>
								<p class="subtitle-2 label white--text">
									Data Comparision:
								</p>
								<p
									class="
										subtitle-2
										label
										mx-2
										month-box
										white--text
									"
								>
									{{ previousMonth }}-{{ currentMonth }}
								</p>
							</div>
							<div
								v-for="action in dataMap.actionValue"
								:key="action.id"
								class="text-center py-8 px-8"
								:class="[
									action.difference >= 0
										? 'green--text'
										: 'red--text',
								]"
							>
								<span class="grey--text text--darken-1"
									>change:</span
								>
								<span class="my-2">
									{{ action.difference }}
								</span>
								<span class="percent-box">
									<v-icon
										v-if="action.difference >= 0"
										color="green"
										>mdi-trending-up</v-icon
									>
									<v-icon v-else color="red"
										>mdi-trending-down</v-icon
									>{{ Math.round(action.percentDifference) }}%
								</span>
							</div>
						</v-card>
					</v-card>
				</v-col>
				<!-- GMB Posts -->
				<v-col cols="12" class="pa-0 mt-4 d-flex">
					<v-card v-if="loading" class="pa-4" outlined width="100%">
						<v-skeleton-loader type="card"></v-skeleton-loader>
					</v-card>
					<v-card v-else class="pa-4" outlined width="100%">
						<h4 class="ma-0">GMB Posts</h4>
						<div class="d-flex justify-center align-center">
							<LineChart
								:height="300"
								:width="900"
								:chart-data="gmbPosts"
								:options="chartOptions"
							></LineChart>
						</div>
						<div
							class="
								d-flex
								justify-start
								align-center
								mt-4
								text-center
							"
						>
							<div class="invisible-box"></div>
							<div
								v-for="gmbData in gmbPosts.datasets"
								:key="gmbData.id"
								class="px-12"
							>
								<p class="d-block subtitle-2 pa-0">
									<span
										class="graph-box"
										:style="{
											'background-color':
												gmbData.backgroundColor,
										}"
									></span
									>{{ gmbData.label }}
								</p>
								<p class="subtitle-2">
									<span class="label">Total : </span
									>{{ gmbData.total }}
								</p>
							</div>
						</div>
						<v-card class="d-flex justify-start align-center mt-4">
							<div
								class="
									d-flex
									align-center
									py-8
									px-6
									compare
									deep-purple
									darken-1
								"
							>
								<p class="subtitle-2 label white--text">
									Data Comparision:
								</p>
								<p
									class="
										subtitle-2
										label
										mx-2
										month-box
										white--text
									"
								>
									{{ previousMonth }}-{{ currentMonth }}
								</p>
							</div>
							<div
								v-for="post in dataMap.posts"
								:key="post.id"
								class="text-center py-8 px-13"
								:class="[
									post.difference >= 0
										? 'green--text'
										: 'red--text',
								]"
							>
								<span class="grey--text text--darken-1"
									>change:</span
								>
								<span class="my-2">
									{{ post.difference }}
								</span>
								<span class="percent-box">
									<v-icon
										v-if="post.difference >= 0"
										color="green"
										>mdi-trending-up</v-icon
									>
									<v-icon v-else color="red"
										>mdi-trending-down</v-icon
									>{{ Math.round(post.percentDifference) }}%
								</span>
							</div>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-row>
	</v-card>
</template>

<script>
import Doughnut from '@/components/Charts/Doughnut.js'
// import Pie from '@/components/Charts/Pie'
import LineChart from '@/components/Charts/LineChart.js'
import GMBScoreCard from './GMBScoreCard'

export default {
	components: {
		LineChart,
		// Pie,
		Doughnut,
		GMBScoreCard,
	},
	data() {
		return {
			menu: false,
			switch1: false,
			date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			dates: [],
			timeline: '7', //default to 7 days ago
			items: ['Last 7 days', 'Last 30 days', 'Last 1 Quarter'],
			qaData: {},
			locations: [],
			selectedLocationName: null,
			locationsArr: [],
			photoViews: {},
			viewListing: {},
			gmbPosts: {},
			customerSearch: {},
			customerActions: {},
			photoQuantity: {},
			dataMap: {
				business: [],
				photoCount: [],
				custmView: [],
				photoView: [],
				actionValue: [],
				posts: [],
				qa: [],
				review: [],
			},
			selectedLocation: null,
			reviewSummary: {},
			loading: false,
			numberInsightsReceived: 0,
			doughnutChartOptions: {
				legend: {
					labels: {
						fontSize: 13,
						usePointStyle: true,
						padding: 50,
					},
					display: false,
					position: 'bottom',
				},
			},
			chartOptions: {
				legend: {
					labels: {
						fontSize: 13,
						usePointStyle: true,
						padding: 50,
					},
					display: false,
					position: 'bottom',
				},
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								userCallback: function (label) {
									// when the floored value is the same as the value we have a whole number
									if (Math.floor(label) === label) {
										return label
									}
								},
							},
							gridLines: {
								display: true,
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: true,
							},
						},
					],
				},
				responsive: true,
				maintainAspectRatio: true,
			},
			months: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			],
		}
	},
	watch: {
		numberInsightsReceived() {
			if (this.numberInsightsReceived === 8) {
				this.loading = false
			}
		},
	},
	created() {
		this.getGmbLocations()
	},
	computed: {
		dateRangeText() {
			this.correctDates()
			return this.dates.join(' ~ ')
		},
		previousMonth() {
			const previous = new Date()
			previous.setMonth(previous.getMonth() - 1)
			const previousMonth = previous.toLocaleString('default', {
				month: 'short',
			})
			return previousMonth
		},
		currentMonth() {
			const current = new Date()
			current.setMonth(current.getMonth())
			const currentMonth = current.toLocaleString('default', {
				month: 'short',
			})
			return currentMonth
		},
	},
	methods: {
		correctDates() {
			// Sometimes user select startDate which is after endDate to fix this problem this is the function
			if (this.dates.length === 2) {
				if (this.dates[0] > this.dates[1]) {
					const temp = this.dates[1] // this is for changing the order so that startDate come at zero index
					this.dates[1] = this.dates[0]
					this.dates[0] = temp
				}
			}
		},
		getInsights() {
			this.menu = false
			this.correctDates()
			if (this.selectedLocationName && this.dates.length === 2) {
				const projectId = this.$store.state.app.projectId
				const locationName = this.selectedLocationName
				let locationEmail = null
				for (let index = 0; index < this.locationsArr.length; index++) {
					const locationObj = this.locationsArr[index]
					if (locationObj.name === this.selectedLocationName) {
						locationEmail = locationObj.email
						break
					}
				}
				this.numberInsightsReceived = 0
				this.loading = true
				this.dataMap = {
					business: [],
					photoCount: [],
					custmView: [],
					photoView: [],
					actionValue: [],
					posts: [],
					qa: [],
					review: [],
				}

				this.getCustomerActionInsights(
					projectId,
					locationName,
					locationEmail
				)
				this.getQueryInsights(projectId, locationName, locationEmail)
				this.getSearchViewsInsights(
					projectId,
					locationName,
					locationEmail
				)
				this.getPhotoViewsInsights(
					projectId,
					locationName,
					locationEmail
				)
				this.getPhotoQuantity(projectId, locationName, locationEmail)
				this.getPostsInsights(projectId, locationName, locationEmail)
				this.getMetricsComparison(
					projectId,
					locationName,
					locationEmail
				)
				this.getQuestionsSummary(locationName)
				this.getReviewSummary(locationName)
			}
		},
		getGmbLocations() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'get',
				url: `/${projectId}/gmb/locations`,
			}).then((res) => {
				this.locationsArr = res.data.data
				res.data.data.map((location) => {
					let locationName = `${location.locationName} (${location.address})`
					this.locations = [...this.locations, locationName]
				})
			})
		},
		setSelected(location) {
			this.selectedLocation = location
			this.locationsArr.forEach((location) => {
				if (
					`${location.locationName} (${location.address})` ===
					this.selectedLocation
				) {
					this.selectedLocationName = location.name
					this.getInsights()
				}
			})
		},
		getMetricsComparison(projectId, locationName, locationEmail) {
			let businessMetric = [
				'QUERIES_DIRECT',
				'QUERIES_INDIRECT',
				'QUERIES_CHAIN',
			]
			let photoMetric = [
				'PHOTOS_COUNT_MERCHANT',
				'PHOTOS_COUNT_CUSTOMERS',
			]
			let viewMetric = ['VIEWS_MAPS', 'VIEWS_SEARCH']
			let picViewMetric = [
				'PHOTOS_VIEWS_MERCHANT',
				'PHOTOS_VIEWS_CUSTOMERS',
			]
			let actionMetric = [
				'ACTIONS_WEBSITE',
				'ACTIONS_PHONE',
				'ACTIONS_DRIVING_DIRECTIONS',
			]
			let gmbQA = ['GMB_QAS']
			let gmbReview = ['GMB_REVIEWS']
			let gmbMetric = ['GMB_POSTS']
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/metric-comparison`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				result.data.data.forEach((val) => {
					var obj = {
						difference: val.difference,
						percentDifference: val.percentDifference,
					}

					if (businessMetric.includes(val.metric)) {
						this.dataMap.business.push(obj)
					}
					if (photoMetric.includes(val.metric)) {
						this.dataMap.photoCount.push(obj)
					}
					if (viewMetric.includes(val.metric)) {
						this.dataMap.custmView.push(obj)
					}
					if (picViewMetric.includes(val.metric)) {
						this.dataMap.photoView.push(obj)
					}
					if (actionMetric.includes(val.metric)) {
						this.dataMap.actionValue.push(obj)
					}
					if (gmbQA.includes(val.metric)) {
						this.dataMap.qa.push(obj)
					}
					if (gmbReview.includes(val.metric)) {
						this.dataMap.review.push(obj)
					}
					if (gmbMetric.includes(val.metric)) {
						this.dataMap.posts.push(obj)
					}
				})
				return this.dataMap
			})
		},
		getReviewSummary(locationName) {
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/review/summary`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
				},
			}).then((response) => {
				this.reviewSummary = response?.data?.data?.reviewSummary || null
				if (this.reviewSummary) {
					this.reviewSummary.averageRating = parseInt(
						this.reviewSummary.averageRating
					)
					let summary = response.data.data.reviewSummary

					this.reviewSummary = {
						labels: ['Negative', 'Positive', 'Neutral', 'Replied'],
						datasets: [
							{
								backgroundColor: [
									'#F5B7B1',
									'#ABEBC6',
									'#F9E79F',
									'#AED6F1',
								],
								data: [
									summary.negetiveReviews,
									summary.positiveReviews,
									summary.neutralReviews,
									summary.repliedTo,
								],
								total:
									summary.negetiveReviews +
									summary.positiveReviews +
									summary.neutralReviews +
									summary.repliedTo,
							},
						],
					}
					this.reviewSummary.averageRating = parseInt(
						summary.averageRating
					)
				}
				this.numberInsightsReceived += 1
			})
		},
		getQuestionsSummary(locationName) {
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/qa/summary`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
				},
			}).then((response) => {
				this.questionSummary = response?.data?.data?.qaSummary || null
				if (this.questionSummary) {
					let summary = response.data.data.qaSummary
					this.qaData = {
						labels: [
							'Questions By Owner',
							'Replied By Owner',
							'Total Questions',
						],
						datasets: [
							{
								backgroundColor: [
									'#F9E79F',
									'#F5B7B1 ',
									'#F5CBA7',
								],
								data: [
									summary.questionsByOwner,
									summary.repliedByOwner,
									summary.totalQuestions,
								],
								total:
									summary.questionsByOwner +
									summary.repliedByOwner +
									summary.totalQuestions,
							},
						],
					}
				}
				this.numberInsightsReceived += 1
			})
		},

		getCustomerActionInsights(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/customer-actions`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let customerActionsData = {
					labels: [],
					datasets: [
						{
							total: 0,
							fill: false,
							borderColor: '#2196F3',
							pointBackgroundColor: '#2196F3',
							pointBorderColor: '#2196F3',
							borderWidth: 1,
							backgroundColor: '#2196F3',
							data: [],
						},
						{
							total: 0,
							fill: false,
							borderColor: '#F50057',
							pointBackgroundColor: '#F50057',
							pointBorderColor: '#F50057',
							borderWidth: 1,
							backgroundColor: '#F50057',
							data: [],
						},
						{
							total: 0,
							fill: false,
							borderColor: '#00E676',
							pointBackgroundColor: '#00E676',
							pointBorderColor: '#00E676',
							borderWidth: 1,
							backgroundColor: '#00E676',
							data: [],
						},
					],
				}
				this.customerActions = customerActionsData
				result.data.data.forEach((metric) => {
					if (metric.metric === 'ACTIONS_WEBSITE') {
						metric.dimensionalValues.forEach((obj) => {
							let date = obj.date
							let month = date.substring(5, 7) - 1
							let day = date.substring(8, 10)
							customerActionsData['labels'].push(
								`${this.months[month]} ${day}`
							)
							customerActionsData.datasets[0].data.push(obj.value)
							customerActionsData.datasets[0].total += obj.value
							customerActionsData.datasets[0].label =
								'Visit Your Website'
						})
					}
					if (metric.metric === 'ACTIONS_DRIVING_DIRECTIONS') {
						metric.dimensionalValues.forEach((obj) => {
							customerActionsData.datasets[1].data.push(obj.value)
							customerActionsData.datasets[1].total += obj.value
						})
						customerActionsData.datasets[1].label =
							'Request Directions'
					}
					if (metric.metric === 'ACTIONS_PHONE') {
						metric.dimensionalValues.forEach((obj) => {
							customerActionsData.datasets[2].data.push(obj.value)
							customerActionsData.datasets[2].total += obj.value
						})
						customerActionsData.datasets[2].label = 'Call you'
					}
				})
				this.numberInsightsReceived += 1
			})
		},
		getQueryInsights(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/query-search`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let QUERIES_DIRECT = 0
				let QUERIES_INDIRECT = 0
				let QUERIES_CHAIN = 0
				result.data.data.forEach((metric) => {
					if (metric.metric === 'QUERIES_DIRECT') {
						let total = 0
						metric.dimensionalValues.forEach((obj) => {
							total = total + obj.value
						})
						QUERIES_DIRECT = total
					}
					if (metric.metric === 'QUERIES_CHAIN') {
						let total = 0
						metric.dimensionalValues.forEach((obj) => {
							total = total + obj.value
						})
						QUERIES_CHAIN = total
						QUERIES_INDIRECT = QUERIES_INDIRECT - QUERIES_CHAIN // because QUERIES_INDIRECT includes QUERIES_CHAIN as QUERIES_CHAIN is subset of QUERIES_INDIRECT
					}
					if (metric.metric === 'QUERIES_INDIRECT') {
						let total = 0
						metric.dimensionalValues.forEach((obj) => {
							total = total + obj.value
						})
						QUERIES_INDIRECT = total
					}
				})
				this.customerSearch = {
					labels: [`Direct`, `Indirect`, `Discovery`],
					datasets: [
						{
							backgroundColor: ['#E91E63', '#00C0F4', '#3F51B5'],
							data: [
								QUERIES_DIRECT,
								QUERIES_INDIRECT,
								QUERIES_CHAIN,
							],
							total:
								QUERIES_DIRECT +
								QUERIES_INDIRECT +
								QUERIES_CHAIN,
						},
					],
				}
				this.numberInsightsReceived += 1
			})
		},
		getSearchViewsInsights(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/search-views`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let viewListingData = {
					fill: false,
					labels: [],
					datasets: [
						{
							total: 0,
							fill: false,
							borderColor: '#039BE5',
							pointBackgroundColor: '#039BE5',
							pointBorderColor: '#039BE5',
							borderWidth: 1,
							backgroundColor: '#039BE5',
							data: [],
						},
						{
							total: 0,
							fill: false,
							borderColor: '#FBC02D',
							pointBackgroundColor: '#FBC02D',
							pointBorderColor: '#FBC02D',
							borderWidth: 1,
							backgroundColor: '#FBC02D',
							data: [],
						},
					],
				}
				this.viewListing = viewListingData

				result.data.data.forEach((metric) => {
					if (metric.metric === 'VIEWS_SEARCH') {
						metric.dimensionalValues.forEach((obj) => {
							viewListingData.datasets[0].data.push(obj.value)
							viewListingData.datasets[0].total += obj.value
						})
						viewListingData.datasets[0].label = 'Listing on search'
					}
					if (metric.metric === 'VIEWS_MAPS') {
						metric.dimensionalValues.forEach((obj) => {
							let date = obj.date
							let month = date.substring(5, 7) - 1
							let day = date.substring(8, 10)
							viewListingData['labels'].push(
								`${this.months[month]} ${day}`
							)
							viewListingData.datasets[1].data.push(obj.value)
							viewListingData.datasets[1].total += obj.value
						})
						viewListingData.datasets[1].label = 'Listing on map'
					}
				})
				this.numberInsightsReceived += 1
			})
		},
		getPhotoViewsInsights(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/photo-views`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let photoViewsData = {
					fill: false,
					labels: [],
					datasets: [
						{
							total: 0,
							fill: false,
							borderColor: '#7B1FA2',
							pointBackgroundColor: '#7B1FA2',
							pointBorderColor: '#7B1FA2',
							borderWidth: 1,
							backgroundColor: '#7B1FA2',
							data: [],
						},
						{
							total: 0,
							fill: false,
							borderColor: '#00796B',
							pointBackgroundColor: '#00796B',
							pointBorderColor: '#00796B',
							borderWidth: 1,
							backgroundColor: '#00796B',
							data: [],
						},
					],
				}
				this.photoViews = photoViewsData

				result.data.data.forEach((metric) => {
					if (metric.metric === 'PHOTOS_VIEWS_MERCHANT') {
						metric.dimensionalValues.forEach((obj) => {
							photoViewsData.datasets[0].data.push(obj.value)
							photoViewsData.datasets[0].total += obj.value
						})
						photoViewsData.datasets[0].label = 'Owner Photos'
					}
					if (metric.metric === 'PHOTOS_VIEWS_CUSTOMERS') {
						metric.dimensionalValues.forEach((obj) => {
							let date = obj.date
							let month = date.substring(5, 7) - 1
							let day = date.substring(8, 10)
							photoViewsData['labels'].push(
								`${this.months[month]} ${day}`
							)
							photoViewsData.datasets[1].data.push(obj.value)
							photoViewsData.datasets[1].total += obj.value
						})
						photoViewsData.datasets[1].label = 'Customer Photos'
					}
				})
				this.numberInsightsReceived += 1
			})
		},
		getPhotoQuantity(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/photo-quantity`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let PHOTOS_COUNT_MERCHANT = 0
				let PHOTOS_COUNT_CUSTOMERS = 0
				result.data.data.forEach((metric) => {
					if (metric.metric === 'PHOTOS_COUNT_MERCHANT') {
						PHOTOS_COUNT_MERCHANT = metric.value
					} else if (metric.metric === 'PHOTOS_COUNT_CUSTOMERS') {
						PHOTOS_COUNT_CUSTOMERS = metric.value
					}
				})
				this.photoQuantity = {
					labels: [`Owner Photos`, `Customer Photos`],
					datasets: [
						{
							backgroundColor: ['#009688', '#3D5AFE'],
							data: [
								PHOTOS_COUNT_MERCHANT,
								PHOTOS_COUNT_CUSTOMERS,
							],
							total:
								PHOTOS_COUNT_MERCHANT + PHOTOS_COUNT_CUSTOMERS,
						},
					],
				}
				this.numberInsightsReceived += 1
			})
		},
		getPostsInsights(projectId, locationName, locationEmail) {
			this.axios({
				method: 'post',
				url: `/${projectId}/gmb/locations/insights/posts`,
				params: {
					startDate: this.dates[0],
					endDate: this.dates[1],
				},
				data: {
					name: locationName,
					email: locationEmail,
				},
			}).then((result) => {
				let gmbPostsData = {
					fill: false,
					labels: [],
					datasets: [
						{
							total: 0,
							fill: false,
							borderColor: '#7B1FA2',
							pointBackgroundColor: '#7B1FA2',
							pointBorderColor: '#7B1FA2',
							borderWidth: 1,
							backgroundColor: '#7B1FA2',
							data: [],
						},
					],
				}
				this.gmbPosts = gmbPostsData
				result.data.data.forEach((obj) => {
					let date = obj.date
					let month = date.substring(5, 7) - 1
					let day = date.substring(8, 10)
					gmbPostsData['labels'].push(`${this.months[month]} ${day}`)
					gmbPostsData.datasets[0].data.push(obj.value)
					gmbPostsData.datasets[0].total += obj.value
				})
				gmbPostsData.datasets[0].label = 'Live Posts'
				this.numberInsightsReceived += 1
			})
		},
	},
}
</script>
<style scoped>
.v-card >>> #doughnut-chart {
	width: 200px !important;
	height: 200px !important;
}
.v-card >>> #pie-chart {
	width: 250px !important;
	height: 250px !important;
}
.small-cards {
	max-height: 370px;
}
.graph-box {
	border: 1px solid;
	height: 1rem;
	width: 1rem;
	margin-right: 0.5rem;
	display: inline-block;
	border-radius: 2px;
}
canvas {
	height: 400px !important;
	width: 400px !important;
}
.no-data {
	max-width: 15rem;
	margin: 0 8rem;
}
.marg-select {
	margin-right: 1rem;
	margin-left: 10rem;
}
.label {
	color: #757575;
}
.compare {
	border-radius: 5px;
}
.month-box {
	border: 2px solid #ffffff;
	border-radius: 5px;
	padding: 0.2rem 0.5rem;
	max-width: 5rem;
}
.percent-box {
	border: 2px solid;
	border-radius: 5px;
	padding: 0.2rem 0.5rem;
}
.invisible-box {
	width: 16rem;
	margin-right: 1rem;
	background-color: red;
}
</style>
