<template>
	<v-container fluid ref="pdf">
		<v-snackbar v-model="snackbar" :timeout="timeout">
			Preparing your PDF
		</v-snackbar>

		<v-tooltip bottom color="primary">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					color="primary"
					class="download-btn"
					elevation="4"
					fixed
					right
					bottom
					fab
					@click="downloadPDF()"
					><span class="mdi mdi-download"></span
				></v-btn>
			</template>
			<span class="tooltip-text">Download dashboard as PDF</span>
		</v-tooltip>

		<Header></Header>

		<ContentManagement
			@timeline-changed="timeline = $event"
		></ContentManagement>

		<WebsiteAndPages></WebsiteAndPages>

		<GMB></GMB>
	</v-container>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Header from './components/Header'
import ContentManagement from './components/ContentManagement'
import WebsiteAndPages from './components/WebsiteAndPages'
import GMB from './components/GMB'
export default {
	components: {
		Header,
		ContentManagement,
		WebsiteAndPages,
		GMB,
	},
	data() {
		return {
			snackbar: false,
			timeout: 3000,
			timeline: '7',
		}
	},
	methods: {
		downloadPDF() {
			this.snackbar = true
			html2canvas(this.$refs.pdf, {
				useCORS: true,
				scrollY: -window.scrollY,
			}).then((canvas) => {
				let nowDate = new Date()
				let name = `${nowDate.getDate()}-${nowDate.getMonth()}-${nowDate.getFullYear()}_Unosearch_Dashboard`
				let imgWidth = 210
				let pageHeight = 295
				let imgHeight = (canvas.height * imgWidth) / canvas.width
				let heightLeft = imgHeight
				let position = 10
				let img = canvas.toDataURL('image/png')

				let pdf = new jsPDF('portrait', 'mm', 'a4')

				pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight)
				heightLeft -= pageHeight
				while (heightLeft >= 0) {
					position += heightLeft - imgHeight // top padding for other pages
					pdf.addPage()
					pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight)
					heightLeft -= pageHeight
				}
				pdf.save(`${name}.pdf`)
			})
		},
	},
}
</script>

<style scoped>
.download-btn {
	margin: 40px;
	font-size: 21px;
}
</style>
