<template>
	<v-row>
		<!-- Customer Search-->
		<v-col cols="6" class="pa-0 mt-4 pr-6 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>How customers search for your business</h4>
				<div
					v-for="(customeData, index) in customerSearch.datasets"
					:key="customeData.id"
					class="d-flex justify-center text-center my-4"
				>
					<p
						v-for="customValue in customeData.data"
						:key="customValue.id"
						class="text-h3 mx-6"
					>
						{{ customValue
						}}<span class="d-block subtitle-2 label">{{
							customerSearch.labels[index++]
						}}</span>
					</p>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightCards in dataMap.business"
						:key="rightCards.id"
						class="text-center col-size pa-0"
						:class="[
							rightCards.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="title my-2">
							{{ rightCards.difference }}
						</p>
						<p>
							<v-icon
								v-if="rightCards.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red"
								>mdi-trending-down</v-icon
							>
							{{ Math.round(rightCards.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- Review Summary -->
		<v-col cols="6" class="pa-0 mt-4 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Review</h4>
				<div
					v-for="(reviewData, index) in reviewSummary.datasets"
					:key="reviewData.id"
					class="d-flex justify-center text-center my-4"
				>
					<p
						v-for="reviewValue in reviewData.data"
						:key="reviewValue.id"
						class="text-h3 mx-6"
					>
						{{ reviewValue
						}}<span class="d-block subtitle-2 label">{{
							reviewSummary.labels[index++]
						}}</span>
					</p>
				</div>
				<!-- <div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="reviewData in dataMap.review"
						:key="reviewData.id"
						class="text-center col-size pa-0"
						:class="[
							reviewData.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="headline my-2">{{ reviewData.difference }}</p>
						<p>
							<v-icon
								v-if="reviewData.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(reviewData.percentDifference) }}%
						</p>
					</v-col>
				</v-row> -->
			</v-card>
		</v-col>
		<!-- Question Summary -->
		<v-col cols="6" class="pa-0 mt-4 pr-6 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Q & A</h4>
				<div
					v-for="(questionData, index) in questionSummary.datasets"
					:key="questionData.id"
					class="d-flex justify-center text-center my-4"
				>
					<p
						v-for="questionValue in questionData.data"
						:key="questionValue.id"
						class="text-h3 mx-6"
					>
						{{ questionValue
						}}<span class="d-block subtitle-2 label">{{
							questionSummary.labels[index++]
						}}</span>
					</p>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="faqData in dataMap.faq"
						:key="faqData.id"
						class="text-center col-size pa-0"
						:class="[
							faqData.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="headline my-2">{{ faqData.difference }}</p>
						<p>
							<v-icon v-if="faqData.difference >= 0" color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(faqData.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- Photo Quantity -->
		<v-col cols="6" class="pa-0 mt-4 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Photo Quantity</h4>
				<div
					v-for="(photoData, index) in photoQuantity.datasets"
					:key="photoData.id"
					class="d-flex justify-center text-center my-4"
				>
					<p
						v-for="photoValue in photoData.data"
						:key="photoValue.id"
						class="text-h3 mx-6"
					>
						{{ photoValue
						}}<span class="d-block subtitle-2 label">{{
							photoQuantity.labels[index++]
						}}</span>
					</p>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightCount in dataMap.photoCount"
						:key="rightCount.id"
						class="text-center col-size pa-0"
						v-bind:class="[
							rightCount.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="title my-2">{{ rightCount.difference }}</p>
						<p>
							<v-icon
								v-if="rightCount.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(rightCount.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- Customer View -->
		<v-col cols="6" class="pa-0 mt-4 pr-6 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Where customers view your business on Google</h4>
				<div class="d-flex justify-center text-center my-4">
					<div
						v-for="customView in viewListing.datasets"
						:key="customView.id"
						class="mx-6"
					>
						<p class="text-h3">{{ customView.total }}</p>
						<p class="subtitle-2 label">{{ customView.label }}</p>
					</div>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightView in dataMap.custmView"
						:key="rightView.id"
						class="text-center col-size pa-0"
						:class="[
							rightView.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="title my-2">{{ rightView.difference }}</p>
						<p>
							<v-icon
								v-if="rightView.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(rightView.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- Photo Views -->
		<v-col cols="6" class="pa-0 mt-4 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Photo Views</h4>
				<div class="d-flex justify-center text-center my-4">
					<div
						v-for="photoView in photoViews.datasets"
						:key="photoView.id"
						class="mx-6"
					>
						<p class="text-h3">{{ photoView.total }}</p>
						<p class="subtitle-2 label">{{ photoView.label }}</p>
					</div>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightPic in dataMap.photoView"
						:key="rightPic.id"
						class="text-center col-size pa-0"
						:class="[
							rightPic.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="title my-2">{{ rightPic.difference }}</p>
						<p>
							<v-icon
								v-if="rightPic.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(rightPic.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- Customer Actions -->
		<v-col cols="6" class="pa-0 mt-4 pr-6 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>Customer Actions</h4>
				<div class="d-flex justify-center text-center my-4">
					<div
						v-for="actionData in customerActions.datasets"
						:key="actionData.id"
						class="mx-6"
					>
						<p class="text-h3">{{ actionData.total }}</p>
						<p class="subtitle-2 label">{{ actionData.label }}</p>
					</div>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightAction in dataMap.actionValue"
						:key="rightAction.id"
						class="text-center col-size pa-0"
						:class="[
							rightAction.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="title my-2">{{ rightAction.difference }}</p>
						<p>
							<v-icon
								v-if="rightAction.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(rightAction.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<!-- GMB Posts-->
		<v-col cols="6" class="pa-0 mt-4 d-flex">
			<v-card v-if="loading" class="pa-4" outlined width="100%">
				<v-skeleton-loader type="card"></v-skeleton-loader>
			</v-card>
			<v-card v-else class="pa-4" outlined width="100%" :elevation="2">
				<h4>GMP Posts</h4>
				<div class="d-flex justify-center text-center my-4">
					<div
						v-for="gmbData in gmbPosts.datasets"
						:key="gmbData.id"
						class="mx-6"
					>
						<p class="text-h3">{{ gmbData.total }}</p>
						<p class="subtitle-2 label">{{ gmbData.label }}</p>
					</div>
				</div>
				<div class="d-flex align-center">
					<p class="subtitle-2 label">Data Comparision:</p>
					<p class="subtitle-2 label mx-2 month-box">
						{{ previousMonth }}-{{ updatedMonth }}
					</p>
				</div>
				<v-row class="justify-center align-center my-2">
					<v-col
						v-for="rightPost in dataMap.posts"
						:key="rightPost.id"
						class="text-center col-size pa-0"
						:class="[
							rightPost.difference >= 0
								? 'green--text'
								: 'red--text',
						]"
					>
						<p class="headline my-2">{{ rightPost.difference }}</p>
						<p>
							<v-icon
								v-if="rightPost.difference >= 0"
								color="green"
								>mdi-trending-up</v-icon
							>
							<v-icon v-else color="red">mdi-trending-down</v-icon
							>{{ Math.round(rightPost.percentDifference) }}%
						</p>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		dataMap: {
			type: Object,
			default: () => ({
				business: [],
				photoCount: [],
				custmView: [],
				photoView: [],
				actionValue: [],
				posts: [],
				faq: [],
				review: [],
			}),
		},
		customerSearch: {
			type: Object,
			default: () => ({}),
		},
		reviewSummary: {
			type: Object,
			default: () => ({}),
		},
		questionSummary: {
			type: Object,
			default: () => ({}),
		},
		photoQuantity: {
			type: Object,
			default: () => ({}),
		},
		viewListing: {
			type: Object,
			default: () => ({}),
		},
		photoViews: {
			type: Object,
			default: () => ({}),
		},
		customerActions: {
			type: Object,
			default: () => ({}),
		},
		gmbPosts: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		previousMonth() {
			const previous = new Date()
			previous.setMonth(previous.getMonth() - 1)
			const previousMonth = previous.toLocaleString('default', {
				month: 'short',
			})
			return previousMonth
		},
		updatedMonth() {
			const current = new Date()
			current.setMonth(current.getMonth())
			const currentMonth = current.toLocaleString('default', {
				month: 'short',
			})
			return currentMonth
		},
	},
}
</script>

<style scoped>
.col-size {
	max-width: 10rem !important;
}
.label {
	color: #757575;
}
.month-box {
	border: 2px solid #757575;
	border-radius: 5px;
	padding: 0.1rem 0.5rem;
}
</style>
