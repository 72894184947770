<template>
	<v-card class="pa-8">
		<v-row class="mx-0">
			<v-col cols="12" class="pa-0">
				<h2>Website and Pages</h2>
			</v-col>
			<v-col cols="6" class="pa-0 mt-4 pr-3 d-flex">
				<v-card class="pa-4" outlined width="100%">
					<v-skeleton-loader
						v-if="loading"
						type="card"
					></v-skeleton-loader>
					<div v-else>
						<h4 class="mb-5">PAGES</h4>
						<div class="d-flex justify-center align-center">
							<Doughnut :chart-data="pageData"></Doughnut>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="6" class="pa-0 mt-4 pl-3 d-flex">
				<v-card class="pa-4" outlined width="100%">
					<v-skeleton-loader
						v-if="loading"
						type="card"
					></v-skeleton-loader>
					<div v-else>
						<v-list flat>
							<div v-if="pages">
								<h4 class="mb-5">List of Website Pages</h4>
								<p
									v-for="(value, pageName) in pages"
									:key="pageName"
								>
									<span
										v-if="value"
										class="pr-3 mdi mdi-check tick-true"
									></span>
									<span
										v-else
										class="pr-3 mdi mdi-check"
									></span>
									{{ pageName }}
								</p>
							</div>
						</v-list>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import Doughnut from '@/components/Charts/Doughnut.js'

export default {
	components: {
		Doughnut,
	},
	data() {
		return {
			loading: true,
			timeline: 7,
			pages: {},
			pagesName: [],
			pageData: {},
		}
	},
	created() {
		this.getDashboard()
	},
	methods: {
		getDashboard() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'get',
				url: `project/${projectId}/dashboard`,
				params: {
					fromDate: this.timeline,
				},
			}).then((res) => {
				const result = res.data.data
				this.pages = result.Pages.created
				this.pagesName = Object.keys(this.pages)
				const created = result.Pages.total - result.Pages.pending
				this.pageData = {
					labels: [
						`Created Pages (${created})`,
						`Pending Pages (${result.Pages.pending})`,
					],
					datasets: [
						{
							backgroundColor: ['#9C27B0', '#00C0F4'],
							data: [created, result.Pages.pending],
						},
					],
				}
				this.loading = false
			})
		},
	},
}
</script>

<style scoped>
.v-card >>> #doughnut-chart {
	width: 250px !important;
	height: 250px !important;
}
canvas {
	height: 400px !important;
	width: 400px !important;
}
.tick-true {
	color: green;
}
</style>
